@import "./base/reset.css";
@import "./abstracts/variables.css";
@import "./abstracts/functions.css";
@import "./abstracts/mixins.css";
@import "./vendors/libs.css";

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", Helvetica, sans-serif;
  box-sizing: border-box;
  text-wrap: balance;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:before,
*:after {
  font-family: "Poppins", Helvetica, sans-serif;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--black-light);
  background: var(--white-dark);
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
p {
  margin-bottom: 0;
}

button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  font: inherit;
  cursor: pointer;
  outline: none;
  color: var(--black);
}

h1,
h5 {
  margin-bottom: 0;
}
a:focus-visible {
  color: var(--orange-light);
  outline: none;
}
button:focus-visible {
  outline: 1px solid var(--green-fosfor) !important;
}

.form-check.radio {
  padding: 0;
  accent-color: var(--orange-light);
}
.form-check-input {
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border: 2px solid var(--orange-light);
  background-color: var(--orange-light);
}

.form-check-input::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--white-light);
  transition: width 0.1s, height 0.1s;
  -webkit-transition: width 0.1s, height 0.1s;
  -moz-transition: width 0.1s, height 0.1s;
  -ms-transition: width 0.1s, height 0.1s;
  -o-transition: width 0.1s, height 0.1s;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.form-check-input:checked {
  background-image: none;
  border-color: var(--orange-light);
  background-color: var(--orange-light);
}
.form-check-input:focus::before,
.form-check-input:hover::before {
  width: 75%;
  height: 75%;
}
.form-check-input:checked::before {
  width: 0;
  height: 0;
}
.form-check-input:focus {
  border-color: var(--orange-light);
  box-shadow: 0 0 0 0 var(--green-250);
}

/* width */
::-webkit-scrollbar {
  height: 7.5px;
  width: 6.5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--orange-light);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

@media (max-width: 500px) {
  .container {
    --bs-gutter-x: 30px !important;
  }
}

/* @media (min-width: 1920px) {
  .container {
    max-width: 1740px;
  }
} */
