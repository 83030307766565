.mt-120 {
  margin-top: var(--main-top);
}

.content-title {
  font-size: 20px;
  color: var(--orange-light);
  font-weight: 400;
  margin-bottom: 0;
}

.dropdown-button,
.button-hover {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.dropdown-button {
  height: 36px;
  padding-bottom: 3px;
  padding-top: 3px;
  background-color: var(--white-light);
}

.button-hover {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--button-hover-height);
  padding: 3px 2.5rem;
  border: 2px solid var(--orange-light);
  color: var(--black) !important;
  position: relative;
  overflow: hidden;
  z-index: 0;
}


.button-hover:disabled {
  opacity: 0.5;
  color: var(--white) !important;
}
.button-hover:disabled::before {
  width: 100%;
}

.button-hover svg {
  color: var(--white) !important;
}

.button-hover.filled {
  background: none;
  border: 2px solid var(--orange-light);
  color: var(--black) !important;
}
.button-hover svg {
  color: var(--black) !important;
  transition: color 0.3s;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -ms-transition: color 0.3s;
  -o-transition: color 0.3s;
}
.button-hover.filled:hover svg {
  color: var(--orange-light) !important;
}
.button-hover.contained svg {
  color: var(--white-light) !important;
}

.button-hover::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 100%;
  background-color: var(--orange-light);
  left: 0;
  top: 0;
  z-index: 0;
  transition: width 0.3s;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
  z-index: -1;
}
.gray-hover {
  min-width: 170px;
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  .gray-hover:hover {
    background-color: var(--white-dark);
  }
  .button-hover:hover::before {
    width: 100%;
  }
  .button-hover.filled,
  .button-hover:hover {
    color: var(--white-light) !important;
  }
}

.button-hover.active,
.button-hover::before {
  color: var(--white-light) !important;
  background-color: var(--orange-light);
}
.button-hover.active::before {
  width: 100%;
}

.button-hover.active svg {
  color: var(--white-light) !important;
}

.button-hover.filled:hover svg,
.button-hover.contained:hover svg,
.button-hover.filled.active,
.button-hover:hover svg {
  color: var(--white-light) !important;
}

.dropdown-button:active {
  opacity: 0.7;
}

.button-hover.contained {
  border: none;
  background-color: var(--white-light);
}

.button-hover.contained svg {
  color: var(--black) !important;
}

.price {
  color: var(--orange-light);
  font-weight: 600;
  text-wrap: nowrap;
}

.input-group-text,
.form-control,
.card {
  border: none;
}

.form-control:focus {
  border-color: var(--orange-light);
  box-shadow: 0 0 0 0.15rem var(--green-250);
}

.input-group-text {
  background-color: var(--white);
}

.input-group-text.right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 50px;
}

.dropdown-toggle {
  border: 2px solid var(--white-light);
  background-color: var(--white-light);
}

.dropdown-menu {
  border: none;
  max-height: 250px;
  overflow: auto;
  scrollbar-gutter: stable;
}

.option {
  cursor: pointer;
}

.option.active,
.option:hover {
  background-color: var(--white-dark);
}

.form-input {
  border-bottom: 2px solid var(--orange-light);
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}
.form-input::placeholder {
  opacity: 0.7;
}
.rotate-vertical {
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
}
.rotate-vertical.rotate-vertical-active {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
}
.link {
  font-weight: 500;
  border-bottom: 2px solid var(--orange-light);
}
.link:hover {
  color: var(--green-fosfor) !important;
  border-bottom: 2px solid var(--green-fosfor);
}

.increment-button {
  display: flex;
  align-items: center;
  border: 2px solid var(--orange-light);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  height: 36px;
  padding: 0 2.5px;
  gap: 2.5px;
}
.increment-button input {
  width: 25px;
  background-color: transparent;
  text-align: center;
  border: none;
  -moz-appearance: textfield;
}
.increment-button input::-webkit-outer-spin-button,
.increment-button input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.gap-37 {
  gap: 37.5px;
}

.font-weight-lightest {
  font-weight: 100;
}
.font-weight-light {
  font-weight: 300;
}
.font-weight-regular {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}
.font-weight-semi-bold {
  font-weight: 600;
}
.font-weight-bold {
  font-weight: 700;
}

.video-section {
  /* position: relative; */
}

.video-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent
    linear-gradient(180deg, #f4f4f400 0%, var(--white-dark) 100%) 0% 0%
    no-repeat padding-box;
  z-index: 1;
}

.video-section .video {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

.video-section .video-text-heading {
  max-width: 100%;
  font-size: 35px;
  font-weight: 600;
  max-width: 500px;
}
.video-section .video-text {
  /* position: absolute;  */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
}
.about-us .video-section .video-text {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
}
.video-section .video-description {
  width: 327px;
  max-width: 100%;
  line-height: 23.1px;
}

.video-section .button-hover {
  height: 32.25px;
  min-height: auto;
  font-size: 16.5px;
  padding: 0;
  width: 112px;
  border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  -moz-border-radius: 7.5px;
  -ms-border-radius: 7.5px;
  -o-border-radius: 7.5px;
}

.support-number {
  height: 40px;
  width: 40px;
  border: 2px solid var(--orange-light);
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.active-header {
  color: var(--orange-light) !important;
}
.scrollbar-stable {
  scrollbar-gutter: stable;
}

/* 0.7 X */
.font-size-42 {
  font-size: 29.4px;
}
.font-size-24 {
  font-size: 16.8px;
}
.font-size-20 {
  font-size: 14px;
}

.font-size-36 {
  font-size: 25.2px;
}

.font-size-32 {
  font-size: 22.4px;
}

.font-size-34 {
  font-size: 23.8px;
}
.font-size-22 {
  font-size: 15.4px;
}
.font-size-18 {
  font-size: 12.6px;
}
.font-size-16 {
  font-size: 11.2px;
}
.font-size-14 {
  font-size: 9.8px;
}
.custom-list {
  list-style-type: disc;
  margin-left: 20px;
}

/* 0.6 */
@media (max-width: 992px) {
  .font-size-42 {
    font-size: 25.2px;
  }
  .font-size-36 {
    font-size: 21.6px;
  }
  .font-size-24 {
    font-size: 14.4px;
  }
  .font-size-32 {
    font-size: 19.2px;
  }
  .font-size-34 {
    font-size: 20.4px;
  }
}

@media (min-width: 992px) {
  .video-section .video-text-heading {
    font-size: 75px;
    line-height: 75px;
  }
  .video-section .button-hover {
    width: 126px;
    height: 35px;
  }
  .content-title {
    font-size: 30px;
  }
  .support-number {
    height: 45.5px;
    width: 45.5px;
    font-size: 20px;
    font-size: 22.4;
  }
}

.information-profile .profile .profile-sub-div .icon svg {
  color: var(--green);
}

.information-profile .profile .profile-sub-div .info p {
  text-align: left;
  letter-spacing: 0px;
  color: var(--black-dark);
  font-weight: normal;
}
.information-profile .profile .profile-sub-div .info p:nth-child(1) {
  opacity: 0.4;
  font-weight: bold;
}

.information-profile .profile .profile-sub-div .adresses p {
  text-align: left;
  letter-spacing: 0px;
  color: var(--black-dark);
  font-weight: normal;
}
.information-profile .profile .profile-sub-div .adresses p:nth-child(1) {
  opacity: 0.4;
  float: left;
  margin-right: 0.3rem;
  font-weight: bold;
}

.information-profile .profile .profile-sub-div .adresses p:nth-child(2) {
  opacity: 0.4;
  font-weight: lighter;
}

.information-profile .profile .profile-sub-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .information-profile .profile .profile-sub-div {
    justify-content: space-between;
  }
  .button-hover {
    padding: 3px 1.2rem;
  }
}

.information-profile .profile .profile-sub-div .image-and-name-surname {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.trash-container {
  background-color: var(--red);
  color: var(--white);
  position: absolute;
  right: 0%;
  top: 0%;
  width: calc(var(--trash-container-width) + 5px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  cursor: pointer;
}

@media (min-width: 992px) {
  .filters .button-hover {
    min-height: 50px;
  }
}

@media (min-width: 1200px) {
  .filters .button-hover {
    font-size: 20px;
  }
}

.min-36 {
  min-height: 36px;
}

/* @keyframes opacity-increase {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.search-product::placeholder {
  opacity: 0;
  animation: opacity-increase 3s forwards;
  -webkit-animation: opacity-increase 3s forwards;
}
 */
.input-mail {
  border: none;
  background-color: var(--white-dark);
}

.input-mail::placeholder {
  font-weight: 600;
  opacity: 0.5;
}

.placeholder {
  opacity: 0.35;
}

.agreement * {
  text-wrap: wrap;
}

.agreement p,
table {
  margin-bottom: 20px;
}

.agreement li:last-child {
  margin-bottom: 20px;
}

.agreement ul {
  list-style-type: disc;
  padding: 0 0px 0 20px;
}
