.order-summary-card.placeholder-glow {
  height: 140px;
}
.order-summary-card + .order-summary-card {
  margin-top: 15px;
}
.order-summary-card a:hover h2 {
  color: var(--orange-light);
}
.order-summary-card {
  --order-summary-card-width: 300px;
  --order-summary-card-img-width: 90px;
  width: var(--order-summary-card-width);
  overflow: hidden;
  max-width: 100%;
  display: flex;
  position: relative;
}

.order-summary-card .order-summary-card__article {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  background-color: var(--white);
  overflow-x: hidden;
  transition: width 0.3s;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
  width: 100%;
}

.order-summary-card .order-summary-card__body {
  width: 100%;
}
.order-summary-card .order-summary-card__body h2,
.order-summary-card .order-summary-card__body p {
  width: 225px;
}

.order-summary-card .order-summary-card__img {
  width: var(--order-summary-card-img-width);
}

.order-summary-card.active .order-summary-card__article {
  width: calc(var(--order-summary-card-width) - var(--trash-container-width));
}

@media (min-width: 576px) {
  .order-summary-card {
    --order-summary-card-width: 400px;
  }
  .order-summary-card .order-summary-card__article {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  .order-summary-card {
    --order-summary-card-width: 450px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .order-summary-card:not(.active):hover .order-summary-card__article {
    width: calc(
      var(--order-summary-card-width) - var(--trash-container-width) / 2
    );
  }
}
