footer .hr {
  background: var(--red-light) !important;
  height: 2px;
}

@media (max-width: 1200px) {
  footer {
    margin-bottom: calc(30px + var(--navigator-height)) !important;
  }
}

@media (max-width: 992px) {
  .product-detail-layout footer {
    margin-bottom: calc(165px + var(--navigator-height)) !important;
  }
}
