.modal-header-area-account-menu {
  position: fixed;
  z-index: 99;
  top: calc(var(--main-top));
  right: 10px;
  min-width: 350px;
  max-width: calc(100% - 40px);
}
.modal-header-account-menu {
  background-color: rgb(251, 251, 251, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: calc(100dvh - var(--main-top) - var(--navigator-height) - 7.5px);
  overflow: auto;
}

@media (min-width: 1200px) {
  .modal-header-account-menu {
    height: calc(100dvh - var(--main-top-header) - 40px);
  }
}
