.recent-post-card.card {
  width: var(--recent-post-card-width);
  height: var(--recent-post-card-height);
  position: relative;
  overflow: hidden;
}

.recent-post-card .recent-post-category {
  font-style: italic;
  font-size: 13px;
}

.recent-post-card .recent-post-title {
  font-weight: 600;
  font-size: 20px;
}

.recent-post-card .recent-post-img {
  min-width: 220px;
  height: 100%;
}

.recent-post-card .recent-post-cover {
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, black);
  width: 100%;
  height: 100%;
  color: var(--white);
}

.recent-post-card .recent-post-cover .button-hover {
  color: var(--white) !important;
}

.recent-post-card .recent-post-cover-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.recent-post-card .recent-post-cover-text {
  font-weight: 300;
}
