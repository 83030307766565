.modal-header-area-cart-menu {
  position: fixed;
  z-index: 99;
  top: var(--main-top);
  right: 0;
  min-width: 350px;
  max-width: calc(100% - 40px);
}

.modal-header-cart-menu {
  background-color: rgb(251, 251, 251, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: calc(100dvh - var(--main-top) - var(--navigator-height) - 7.5px);
  overflow: auto;
}

@media (min-width: 1200px) {
  .modal-header-cart-menu {
    height: calc(100dvh - var(--main-top-header) - 40px);
  }
}
