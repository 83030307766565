.navigator {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: var(--navigator-height);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  z-index: 999;
}

.navigator a,
.navigator button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
}
.navigator .navigator-title {
  font-size: 12px;
}

@media (max-width: 992px) {
  .product-detail-layout .navigator {
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
  }
}

@media (max-width: 375px) {
  .navigator .navigator-title {
    font-size: 10px;
  }
}
