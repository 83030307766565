header.xl {
  opacity: 1;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  position: fixed;
  z-index: 99;
  width: 100%;
  left: 0%;
  top: 0%;
  height: var(--main-top-header);
  z-index: 999999;
}
header.xl:hover,
header.xl.active {
  background-color: var(--white-dark);
}
header.xl .header-logo {
  width: 100px;
}

header.xl .nav-menu ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
header.xl .nav-menu li button {
  color: inherit;
}
header.xl .nav-menu li,
header.xl .nav-menu li button {
  position: relative;
}
header.xl .nav-menu li a,
header.xl .nav-menu li button {
  font-size: 15px;
  white-space: nowrap;
  font-weight: 500;
}
header.xl .nav-menu li a:after,
header.xl .nav-menu li button:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--orange-light);
  transform: scaleX(0);
  transform-origin: left;
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -ms-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
}

header.xl .nav-menu li button:hover:after,
header.xl .nav-menu li a:hover:after {
  width: 100%;
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
}

header.xl .nav-menu li a:hover,
header.xl .nav-menu li button:hover {
  color: var(--orange-light) !important;
}

header.xl .icons .icon.first {
  font-size: 24px;
}
header.xl .icons .icon {
  font-size: 20px;
  width: 24px;
}
header.xl .icons .icon:hover {
  color: var(--orange-light);
}
header.xl .icons .icon.active {
  color: var(--orange-light);
}
.quick-link {
  color: rgba(38, 38, 38, 0.4);
}

.modal-header-menu {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  height: 100%;
  position: fixed;
  top: var(--main-top-header);
  left: 0%;
  width: 100%;
  z-index: 99;
}
.modal-header-menu .modal-header-content {
  height: 275px;
  background-color: var(--white-dark);
}
.modal-header-menu .vertical-menu .dropdown-menu-link {
  position: relative;
}

.modal-header-menu .vertical-menu .dropdown-menu-link:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: var(--orange-light);
  transform: scaleX(0);
  transform-origin: left;
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -ms-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
}
.modal-header-menu .vertical-menu .dropdown-menu-link:hover:after {
  width: 100%;
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
}
.modal-header-menu .vertical-menu .dropdown-menu-link:hover {
  color: var(--orange-light);
}
