footer.xs {
  /* margin-bottom: 82px; */
  font-size: 14px;
  margin-bottom: 68px;
}

footer.xs .footer-logo {
  width: 82.75px;
}

footer.xs .footer-bottom {
  font-size: 12px;
}
footer.xs .accordion-button {
  font-weight: 600;
}

footer.xs .sub {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

footer.xs .sub::-webkit-scrollbar {
  display: none;
}

footer.xs .sub a {
  display: none;
}

footer.xs .sub.active {
  max-height: 300px;
  overflow: auto;
}
footer.xs .sub.active a {
  display: inline !important;
}
