header.xs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  z-index: 999999;
}
header.xs .toggler-wrapper {
  height: var(--main-top-header);
}
header.xs .header-logo {
  width: 40px;
}

header.xs .form-control:focus {
  border-color: var(--gray);
  box-shadow: 0 0 0 0.15rem var(--gray-250);
}

header.xs.bg {
  background-color: var(--white-dark);
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
}
header.xs .header-xs-content {
  scrollbar-gutter: stable;
  height: 0;
  width: 100%;
  transition: height 0.3s linear;
  overflow: auto;
  -webkit-transition: height 0.3s linear;
  -moz-transition: height 0.3s linear;
  -ms-transition: height 0.3s linear;
  -o-transition: height 0.3s linear;
}

header.xs ul li:not(:last-child) {
  margin-bottom: 12px;
}

header.xs ul li a,
header.xs ul li button {
  font-weight: 500;
  font-size: 18px;
  color: inherit;
}

header.xs ul.sub li:not(:last-child) {
  margin-bottom: 15px;
}

header.xs ul.sub li:first-child {
  margin-top: 10px;
}
header.xs ul.sub li a {
  font-size: 16px;
  font-weight: 400;
}

header.xs ul.sub {
  max-height: 0;
  transition: max-height 0.5s;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  overflow: hidden;
}
header.xs ul.sub li {
  display: none;
}
header.xs ul.sub.active {
  max-height: 310px;
}
header.xs ul.sub.active li {
  display: block;
}

header.xs.active {
  background-color: var(--white-dark);
}

@media (hover: hover) and (pointer: fine) {
  header.xs ul li a,
  header.xs ul li button {
    position: relative;
  }
  header.xs ul li button:hover,
  header.xs ul li a:hover {
    color: var(--orange-light);
  }
  header.xs ul li a:after,
  header.xs ul li button:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: var(--orange-light);
    transform: scaleX(0);
    transform-origin: left;
    transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    -webkit-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    -ms-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  header.xs ul li button:hover:after,
  header.xs ul li a:hover:after {
    width: 100%;
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
  }
  header.xs nav a:hover svg {
    color: var(--orange-light);
  }
}
header.xs.active .header-xs-content {
  height: calc(100dvh - var(--main-top-header) - var(--navigator-height) * 2);
}

header.xs .header-logo.header-logo-sidemenu-bottom{
  width: 10rem ;
  margin-top: 100%;
}