.vertical-menu nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.vertical-menu nav li {
  display: inline;
  margin-right: 20px;
}

.vertical-menu nav a {
  text-decoration: none;
  color: var(--black-dark);
  font-weight: bold;
}

.vertical-menu .dropdown-menu-link {
  position: relative;
}

.vertical-menu .dropdown-menu-link {
  white-space: nowrap;
}

.vertical-menu .dropdown-menu-link:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: var(--orange-light);
  transform: scaleX(0);
  transform-origin: left;
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -ms-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.vertical-menu .dropdown-menu-link:hover:after {
  width: 100%;
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
}

.vertical-menu .dropdown-menu-link:hover {
  color: var(--orange-light);
}

@media (max-width: 1199.8px) {
  .modal-header-menu {
    height: calc(
      100% - var(--navigator-height) - var(--main-top-header)
    ) !important;
  }
  .modal-header-menu .modal-header-content {
    height: calc(
      100% - var(--navigator-height) - var(--main-top-header)
    ) !important;
  }
}
