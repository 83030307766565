footer.xl {
  margin-bottom: var(--main-top);
}

footer.xl .footer-logo {
  width: 100px;
}

footer.xl .icons svg {
  font-size: 26px;
}

footer.xl .icons .twitter {
  font-size: 22px;
}

.footer-bottom {
  font-size: 12px;
}

footer.xl a:hover {
  color: var(--orange-light);
}

.icons-social-footer a svg {
  transition: all .3s ease-out;
}

.icons-social-footer a svg:hover {
  color: var(--orange-light);

}

@media (min-width: 1200px) {
  .footer-bottom {
    font-size: 14px;
  }
}

/* @media (min-width: 1920px) {
  footer.xl .footer-logo {
    width: 50px;
  }
  .footer-bottom {
    font-size: 16px;
  }
  footer.xl .icons {
    gap: 15px !important;
  }
  footer.xl .icons svg {
    font-size: 30px;
  }

  footer.xl .icons .twitter {
    font-size: 26px;
  }
} */
