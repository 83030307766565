.gradient-background {
  position: fixed;
  z-index: -1;
  background: var(--orange-lighter);
  height: 50vh;
  width: 50vw;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  filter: blur(150px);
  -webkit-filter: blur(150px);
}

.gradient-background.gradient-background-right-top {
  top: 25%;
  right: 0;
  transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  -o-transform: translate(50%, -50%);
}
.gradient-background.gradient-background-left-bottom {
  bottom: 25%;
  left: 0;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, 50%);
  -moz-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  -o-transform: translate(-50%, 50%);
}
