:root {
  /* COLORS */
  --black-dark: #262626;
  --black: #262a34;
  --black-500: rgb(38, 38, 38, 0.5);
  --black-light: #343434;
  --black-title: #2c2c2c;
  --green-dark: #325b39;
  --green: #40826d;
  --green-250: rgb(64, 130, 109, 0.25);
  --green-light: #c7d3ce;
  --green-fosfor: #7fda69;
  --white-dark: #f4f4f4;
  --white: #fbfbfb;
  --white-light: #fff;
  --gold: #dce622;
  --gray-light: #898989;
  --gray: #d8d8d8;
  --gray-250: rgb(216, 216, 216, 0.25);
  --red: #ed4747;
  --red-light: #ebd8d8;
  --red-dark:#b62008;
  --red-darker:#9B1803;
  --blue-light:#3939ff;
  --blue-dark:#3e3ec4;
  --orange-light:#fc5b22;
  --orange-lighter:#eecec2;
  --orange-dark:#de5220;


  /* Tech Areas */
  --reaction: #1b9b51;
  --mechanic: #fc5b22;
  --innovation: #82c7d1;
  --smart: #3939ff;
  --chemical: #dbb8ff;
  --energy: #f2bb05;
  --materials: #d51c29;

  /* GRID */
  --bs-gutter-x: 25px;
  --bs-gutter-y: 25px;
  --container-xs: 475px;
  --container-sm: 540px;
  --container-md: 720px;
  --container-lg: 960px;
  --container-xl: 1140px;
  --container-xxl: 1320px;
  --container-xxxl: 1720px;

  /* PRODUCT CARD */
  --product-card-width: 165px;
  --product-card-height: 305px;
  --fav-button-height: 32px;
  --trash-container-width: 55px;

  /* ORDER SUMMARY CARD */
  --order-summary-card-width: 100%;
  --order-summary-card-height: 415px;

  /* SERVICES CARD */
  --services-card-width: 215px;
  --services-card-height: 300px;

  --main-top: 67.5px;
  --main-top-header: 60px;
  --navigator-height: 80px;
  --btn-hover-height: 40px;
}

.card {
  --bs-card-spacer-y: 10px;
  --bs-card-spacer-x: 10px;
}

@media (hover: hover) and (pointer: fine) {
  :root {
    --product-card-height: 268px;
  }
}

@media (min-width: 768px) {
  :root {
    --product-card-width: 200px;
    --product-card-height: 360px;
  }
}

@media (min-width: 768px) and (hover: hover) and (pointer: fine) {
  :root {
    --product-card-height: 322px;
  }
}

@media (min-width: 992px) {
  .card {
    --bs-card-spacer-y: 15px;
    --bs-card-spacer-x: 15px;
  }
  :root {
    --btn-hover-height: 50px;

    /* SERVICES CARD */
    --services-card-width: 245px;
    --services-card-height: 343px;
  }
}

@media (min-width: 1200px) {
  :root {
    --main-top: 130px;
    --main-top-header: 110px;

    --product-card-width: 230px;
    --product-card-height: 390px;
  }
}

@media (min-width: 1200px) and (hover: hover) and (pointer: fine) {
  :root {
    --product-card-height: 350px;
  }
}

@media (min-width: 1400px) {
  :root {
    --btn-hover-height: 55px;
    --product-card-width: 210px;
    --product-card-height: 370px;
  }
}
@media (min-width: 1400px) and (hover: hover) and (pointer: fine) {
  :root {
    --product-card-height: 330px;
  }
}
