.form-control {
  appearance: auto;
}
.border-success {
  border-color: var(--orange-light) !important;
}

.bg-success {
  background-color: var(--orange-light) !important;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl,
.container-xxxl {
  --bs-gutter-x: 15px !important;
}

.row {
  --bs-gutter-x: 15px !important;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 5px !important;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 10px !important;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 15px !important;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 20px !important;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 25px !important;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 30px !important;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 35px !important;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 40px !important;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 45px !important;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 50px !important;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 5px !important;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 10px !important;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 15px !important;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 20px !important;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 25px !important;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 30px !important;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 35px !important;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 40px !important;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 45px !important;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 50px !important;
}

.m-1 {
  margin: 5px !important;
}
.m-2 {
  margin: 10px !important;
}
.m-3 {
  margin: 15px !important;
}
.m-4 {
  margin: 20px !important;
}
.m-5 {
  margin: 25px !important;
}
.m-6 {
  margin: 30px !important;
}
.m-7 {
  margin: 35px !important;
}
.m-8 {
  margin: 40px !important;
}
.m-9 {
  margin: 45px !important;
}
.m-10 {
  margin: 50px !important;
}

.mt-1 {
  margin-top: 5px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mt-3 {
  margin-top: 15px !important;
}
.mt-4 {
  margin-top: 20px !important;
}
.mt-5 {
  margin-top: 25px !important;
}
.mt-6 {
  margin-top: 30px !important;
}
.mt-7 {
  margin-top: 35px !important;
}
.mt-8 {
  margin-top: 40px !important;
}
.mt-9 {
  margin-top: 45px !important;
}
.mt-10 {
  margin-top: 50px !important;
}

.mb-1 {
  margin-bottom: 5px !important;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.mb-3 {
  margin-bottom: 15px !important;
}
.mb-4 {
  margin-bottom: 20px !important;
}
.mb-5 {
  margin-bottom: 25px !important;
}
.mb-6 {
  margin-bottom: 30px !important;
}
.mb-7 {
  margin-bottom: 35px !important;
}
.mb-8 {
  margin-bottom: 40px !important;
}
.mb-9 {
  margin-bottom: 45px !important;
}
.mb-10 {
  margin-bottom: 50px !important;
}
.mb-20 {
  margin-bottom: 100px !important;
}

.mx-1 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.mx-2 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.mx-3 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.mx-4 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}
.mx-5 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}
.mx-6 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}
.mx-7 {
  margin-right: 35px !important;
  margin-left: 35px !important;
}
.mx-8 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}
.mx-9 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}
.mx-10 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}

.my-1 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.my-2 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my-3 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.my-4 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.my-5 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.my-6 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.my-7 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}
.my-8 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.my-9 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}
.my-10 {
  margin-right: 50px !important;
  margin-bottom: 50px !important;
}

.ms-1 {
  margin-left: 5px !important;
}
.ms-2 {
  margin-left: 10px !important;
}
.ms-3 {
  margin-left: 15px !important;
}
.ms-4 {
  margin-left: 20px !important;
}
.ms-5 {
  margin-left: 25px !important;
}
.ms-6 {
  margin-left: 30px !important;
}
.ms-7 {
  margin-left: 35px !important;
}
.ms-8 {
  margin-left: 40px !important;
}
.ms-9 {
  margin-left: 45px !important;
}
.ms-10 {
  margin-left: 50px !important;
}

.me-1 {
  margin-right: 5px !important;
}
.me-2 {
  margin-right: 10px !important;
}
.me-3 {
  margin-right: 15px !important;
}
.me-4 {
  margin-right: 20px !important;
}
.me-5 {
  margin-right: 25px !important;
}
.me-6 {
  margin-right: 30px !important;
}
.me-7 {
  margin-right: 35px !important;
}
.me-8 {
  margin-right: 40px !important;
}
.me-9 {
  margin-right: 45px !important;
}
.me-10 {
  margin-right: 50px !important;
}

.p-1 {
  padding: 5px !important;
}
.p-2 {
  padding: 10px !important;
}
.p-3 {
  padding: 15px !important;
}
.p-4 {
  padding: 20px !important;
}
.p-5 {
  padding: 25px !important;
}
.p-6 {
  padding: 30px !important;
}
.p-7 {
  padding: 35px !important;
}
.p-8 {
  padding: 40px !important;
}
.p-9 {
  padding: 45px !important;
}
.p-10 {
  padding: 50px !important;
}
.pl-1 {
  padding-left: 5px;
}
.pr-1 {
  padding-right: 5px;
}
.px-1 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.px-2 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.px-3 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.px-4 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.px-5 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}
.px-6 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}
.px-7 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}
.px-8 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.px-9 {
  padding-right: 45px !important;
  padding-left: 45px !important;
}
.px-10 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}

.py-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.py-2 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.py-3 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.py-4 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.py-5 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.py-6 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.py-7 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.py-8 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.py-9 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.py-10 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.pt-5 {
  padding-top: 25px !important;
}

.ps-1 {
  padding-left: 5px !important;
}
.ps-2 {
  padding-left: 10px !important;
}
.ps-3 {
  padding-left: 15px !important;
}
.ps-4 {
  padding-left: 20px !important;
}
.ps-5 {
  padding-left: 25px !important;
}
.ps-6 {
  padding-left: 30px !important;
}
.ps-7 {
  padding-left: 35px !important;
}
.ps-8 {
  padding-left: 40px !important;
}
.ps-9 {
  padding-left: 45px !important;
}
.ps-10 {
  padding-left: 50px !important;
}

.pe-1 {
  padding-right: 5px !important;
}
.pe-2 {
  padding-right: 10px !important;
}
.pe-3 {
  padding-right: 15px !important;
}
.pe-4 {
  padding-right: 20px !important;
}
.pe-5 {
  padding-right: 25px !important;
}
.pe-6 {
  padding-right: 30px !important;
}
.pe-7 {
  padding-right: 35px !important;
}
.pe-8 {
  padding-right: 40px !important;
}
.pe-9 {
  padding-right: 45px !important;
}
.pe-10 {
  padding-right: 50px !important;
}

.gap-1 {
  gap: 5px !important;
}
.gap-2 {
  gap: 10px !important;
}
.gap-3 {
  gap: 15px !important;
}
.gap-4 {
  gap: 20px !important;
}
.gap-5 {
  gap: 25px !important;
}
.gap-6 {
  gap: 30px !important;
}
.gap-7 {
  gap: 35px !important;
}

.gap-8 {
  gap: 40px !important;
}
.gap-9 {
  gap: 45px !important;
}
.gap-10 {
  gap: 50px !important;
}

.border-radius-1 {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}
.border-radius-2 {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}
.border-radius-3 {
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
}
.border-radius-4 {
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}
.border-radius-5 {
  border-radius: 25px !important;
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  -o-border-radius: 25px !important;
}
.border-radius-6 {
  border-radius: 30px !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
}
.border-radius-7 {
  border-radius: 35px !important;
  -webkit-border-radius: 35px !important;
  -moz-border-radius: 35px !important;
  -ms-border-radius: 35px !important;
  -o-border-radius: 35px !important;
}
.border-radius-8 {
  border-radius: 40px !important;
  -webkit-border-radius: 40px !important;
  -moz-border-radius: 40px !important;
  -ms-border-radius: 40px !important;
  -o-border-radius: 40px !important;
}
.border-radius-9 {
  border-radius: 45px !important;
  -webkit-border-radius: 45px !important;
  -moz-border-radius: 45px !important;
  -ms-border-radius: 45px !important;
  -o-border-radius: 45px !important;
}
.border-radius-10 {
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}

@media (min-width: 768px) {
  .border-radius-md-1 {
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
  }
  .border-radius-md-2 {
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
  }
  .border-radius-md-3 {
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    -ms-border-radius: 15px !important;
    -o-border-radius: 15px !important;
  }
  .border-radius-md-4 {
    border-radius: 20px !important;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    -ms-border-radius: 20px !important;
    -o-border-radius: 20px !important;
  }
  .gap-md-7 {
    gap: 35px !important;
  }
  .gap-md-8 {
    gap: 40px !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .border-radius-lg-1 {
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
  }
  .border-radius-lg-2 {
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
  }
  .border-radius-lg-3 {
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    -ms-border-radius: 15px !important;
    -o-border-radius: 15px !important;
  }
  .border-radius-lg-4 {
    border-radius: 20px !important;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    -ms-border-radius: 20px !important;
    -o-border-radius: 20px !important;
  }
  .border-radius-lg-5 {
    border-radius: 25px !important;
    -webkit-border-radius: 25px !important;
    -moz-border-radius: 25px !important;
    -ms-border-radius: 25px !important;
    -o-border-radius: 25px !important;
  }
  /* PADDING */

  .pb-lg-1 {
    padding-bottom: 5px !important;
  }
  .pb-lg-4 {
    padding-bottom: 20px !important;
  }
  .p-lg-3 {
    padding: 15px !important;
  }
  .p-lg-4 {
    padding: 20px !important;
  }
  .p-lg-7 {
    padding: 35px !important;
  }
  .g-lg-7 {
    --bs-gutter-y: 35px !important;
    --bs-gutter-x: 35px !important;
  }
  .gx-lg-7 {
    --bs-gutter-x: 40px !important;
  }

  .gy-lg-7 {
    --bs-gutter-y: 35px !important;
  }

  .gap-lg-2 {
    gap: 10px !important;
  }

  .gap-lg-7 {
    gap: 35px !important;
  }
  .gap-lg-8 {
    gap: 40px !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-2 {
    margin-top: 10px !important;
  }
  .mt-lg-3 {
    margin-top: 15px !important;
  }
  .mt-lg-5 {
    margin-top: 25px !important;
  }
  .mt-lg-6 {
    margin-top: 30px !important;
  }
  .mt-lg-7 {
    margin-top: 35px !important;
  }
  .mt-lg-10 {
    margin-top: 50px !important;
  }
  .mb-lg-6 {
    margin-bottom: 30px !important;
  }
  .mb-lg-7 {
    margin-bottom: 35px !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-4 {
    margin-bottom: 20px !important;
  }

  .ms-lg-4 {
    margin-left: 20px !important;
  }
}

@media (min-width: 1200px) {
  .ms-xl-10 {
    margin-left: 50px !important;
  }
  .gap-xl-7 {
    gap: 35px !important;
  }
  .gap-xl-10 {
    gap: 50px !important;
  }
  .mt-xl-7 {
    margin-top: 35px !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 1400px) {
  .flex-grow-xxl-1 {
    flex-grow: 1 !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .ms-xxl-30 {
    margin-left: 150px !important;
  }
  .gap-xxl-15 {
    gap: 75px !important;
  }
  .gap-xxl-5 {
    gap: 25px !important;
  }
}
