.product-searchbar-wrapper {
  position: relative;
  z-index: 3;
  transform-style: preserve-3d;
  perspective: 800px;
}

.product-searchbar-wrapper .product-search-bar__list {
  position: absolute;
  width: 100%;
  z-index: 10;
  background-color: var(--white) !important;
  scrollbar-gutter: stable;
  max-height: 250px;
  overflow: auto;
}

.product-searchbar-wrapper .product-search-bar__card:hover {
  color: var(--green) !important;
}

.product-searchbar-wrapper .search-bar-label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  backface-visibility: hidden;
  z-index: 4;
}

.product-searchbar-wrapper .search-bar-label.one {
}
.product-searchbar-wrapper .search-bar-label.two {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
}
.product-searchbar-wrapper .search-bar-label.three {
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
}
.product-searchbar-wrapper .search-bar-label.four {
  transform: rotateX(-90deg);
  -webkit-transform: rotateX(-90deg) translateZ(100px);
  -moz-transform: rotateX(-90deg) translateZ(100px);
  -ms-transform: rotateX(-90deg) translateZ(100px);
  -o-transform: rotateX(-90deg) translateZ(100px);
}
